import React from 'react';

const VideoChange = () => {
    return (
        <div>
        <h1>Video Change</h1>
        </div>
    );
};

export default VideoChange;