import React from "react";
import styled from "styled-components";
import { FOOTER_HEIGHT } from '../../constants/constants';
import ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

// eslint-disable-next-line
const FooterContainer = styled.footer`
    display: flex;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    height: ${FOOTER_HEIGHT}px;
    background-color: #F7F9FA; /* 헤더 배경색을 원하는 색으로 변경하세요 */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    & > div {
        margin-top: 1rem;
        margin-left: 2rem;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        }

    @media (max-width: 1280px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        
        & > div {
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        }
    }
`;

const MenuContainer = styled.nav`
    display: grid;
    margin-right: 82px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 5px;
    align-items: center;
    color: #000000; /* 메뉴 글자색을 원하는 색으로 변경하세요 */

    & > a {
        display: grid;
        text-align: left;
        padding: 10px;
        text-decoration-line: none;
        color: #000000; /* 메뉴 글자색을 원하는 색으로 변경하세요 */
        background-color: #F7F9FA; /* 메뉴 배경색을 원하는 색으로 변경하세요 */
        border-radius: 4px;
    }

    & > a:hover {
        color: black;
        background-color: #0000001f;
        border-radius: 4px;
    }

    & > a:active {
        color: black;
        background-color: #0000001f;
        border-radius: 4px;
    }

    @media (max-width: 1280px) {
        display: none;
        
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-right: 82px;

    & > a {
        display: flex;
        margin-right: 4px;
        text-decoration-line: none;
        font-size:12px;
        padding: 0 4px;
        color: #000000; /* 메뉴 글자색을 원하는 색으로 변경하세요 */
        background-color: #F7F9FA; /* 메뉴 배경색을 원하는 색으로 변경하세요 */
    }

    @media (max-width: 1280px) {
        display: block;
        width: 100%;
        margin: 0 auto;
        & > a {
            display: block;
            margin: 0 auto;
            text-align: center;
            padding: 2px 0px 2px 0px;
        }
    }
`;

const Logo = styled.div`
    font-size: 28px;
    margin-top: 1rem;
    margin-right: auto;
    font-weight: bold;
    color: #000000; /* 로고 글자색을 원하는 색으로 변경하세요 */
    text-decoration-line: none;
    cursor: pointer;

    @media (max-width: 1280px) {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 0% 0% 0% 0%;
    }
`;

const Copyright = styled.div`
    margin-right: auto;
    margin-left: 4px;
    font-size: 12px;
    color: #000000; /* 로고 글자색을 원하는 색으로 변경하세요 */
    text-decoration-line: none;


    @media (max-width: 1280px) {
        width: 100%;
        margin-top: 5px;
        text-align: center;
        padding: 0% 0% 0% 0%;
    }
`;

const SeparatorBar = styled.p`
    margin: 0 px;
    font-size: 12px;
    color: rgb(124, 124, 124); /* 로고 글자색을 원하는 색으로 변경하세요 */

    @media (max-width: 1280px) {
        display: none;
    }
`

const Footer = () => {
    return (
        <FooterContainer>
            <div>
                <Logo>
                    WORKPAGE
                </Logo>
                <LogoContainer>
                    <a href="/">개인정보처리방침</a>
                    <SeparatorBar>|</SeparatorBar>
                    <a href="/">이용약관</a>
                    <SeparatorBar>|</SeparatorBar>
                    <a href="/">청소년보호정책</a>
                    <SeparatorBar>|</SeparatorBar>
                    <a href="/">사업자정보확인</a>
                </LogoContainer>
                <Copyright>
                    Copyright LIFEPAGE. all rights reserved
                </Copyright>
            </div>
            <MenuContainer>
                {ROUTES.map((route) => (
                <Link to={route.path} key={route.path}>
                {route.name}
            </Link>
            ))}
            </MenuContainer>

        </FooterContainer>
    );
}

export default Footer;