import React from 'react';

const ImageChange = () => {
    return (
        <div>
        <h1>Image Change</h1>
        </div>
    );
};

export default ImageChange;