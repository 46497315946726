
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import ROUTES from '../constants/routes';
import styled from "styled-components";
import { HEADER_HEIGHT, FOOTER_HEIGHT } from '../constants/constants';



const Layout = () => {
    return (
        <>
        <BrowserRouter>
        <div>
        <Header/>
        <Main>
        <Routes location={window.location}>
        {ROUTES.map((route) => {
          return (
            <Route path={route.path} element= {<route.component/>}/>
          );
        })}
      </Routes>
        </Main>
          <Footer/>
          </div>
        </BrowserRouter>
        </>
    );
}

const Main = styled.main`
  padding-top: ${HEADER_HEIGHT}px;
  top: ${HEADER_HEIGHT}px;
  height: 100%;
  min-height: ${`calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`};
  padding-bottom:${FOOTER_HEIGHT}px;
  background-color: #f5f5f5;
`;

export default Layout;