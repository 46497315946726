import './App.css';
import React from 'react';
import Layout from './components/Layout';


function App() {
  return (
      <div className="App">
        <Layout/>        
        </div>
  );
}

export default App;
