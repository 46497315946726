import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../constants/constants';
import ROUTES from '../../constants/routes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line
const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  background-color: #ffffff; /* 헤더 배경색을 원하는 색으로 변경하세요 */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 1280px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
  }
`;

const Logo = styled(Link)`
  padding: 0 48px;
  margin-right: 82px;
  font-size: 24px;
  font-weight: bold;
  color: #000000; /* 로고 글자색을 원하는 색으로 변경하세요 */
  text-decoration-line: none;
  cursor: pointer;

  a:hover {
    color: black;
    background-color: #0000001f;
    border-radius: 4px;
  }

  @media (max-width: 1280px) {
    padding: 0 16px;
    margin-right: 0px;
  }
`;

const MenuContainer = styled.nav`
  display: flex;
  align-items: center;
  color: #000000; /* 메뉴 글자색을 원하는 색으로 변경하세요 */
  
  & > a {
    display: block;
    justify-content: space-between;
    padding: 10px;
    text-decoration-line: none;
    color: #000000; /* 메뉴 글자색을 원하는 색으로 변경하세요 */
    background-color: #ffffff; /* 메뉴 배경색을 원하는 색으로 변경하세요 */
  }

  & > a:hover {
    color: #ffffff;
    background-color: #929292;
    border-radius: 4px;
  }

  & > a:active {
    color: #ffffff;
    background-color: #929292;
    border-radius: 4px;
  }

  @media (max-width: 1280px) {
    display: block;
    position: fixed;
    top: 70px;
    width: 100%;

    & > a {
    display: ${(props) => (props.isMenuOpen ? "block" : "none")};
    justify-content: space-between;
    padding: 10px;
    text-decoration-line: none;
    color: #000000; /* 메뉴 글자색을 원하는 색으로 변경하세요 */
    background-color: #ffffff; /* 메뉴 배경색을 원하는 색으로 변경하세요 */
    }
}
`;

const MenuToggle = styled.div`
  display: none;
  cursor: pointer;
  font-size: 24px;
  background-color: #ffffff;

  @media (max-width: 1280px) {
    display: flex;
    align-items: center;
    margin-right: 16px;
    float: right;
    border-radius: 4px;
  }
`;

const Header = () => { 
    const [isMenuOpen, setMenuOpen] = useState(false); 
    const toggleMenu = () => {                         
      setMenuOpen(!isMenuOpen);                        
    };
    return (
        <HeaderContainer>
         <Logo to="/">
          WORK PAGE
        </Logo>
        <MenuToggle onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars}/>
        </MenuToggle>
        <MenuContainer isMenuOpen ={isMenuOpen}>
        {ROUTES.map((route) => (
          <Link to={route.path} key={route.path}>
            {route.name}
          </Link>
        ))}
        </MenuContainer>
        </HeaderContainer>
    );
}

export default Header;