import React from 'react';
import styled from 'styled-components';
import URLItem from '../../components/URLItem/urlItem';
import banner from '../../assets/pictures/banner.png';

const TitleArea = styled.div`
    text-align: center;
    margin-bottom: auto;
    padding: 10px;
    background-color: #F7F9FA;

    & > div {
        margin-top: auto;
        padding: 1rem;
        font-size: 1rem;
        margin: 0;
    }
`;

/* 메인 영역 */
const MainArea = styled.div`
    min-height: 300px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
    background-color: #ffffff;

    /* 자식 영역 */
    & > div { 
        display: flex;
        justify-content: space-between;
        width: 80%;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;
    }
    /*Main button*/ 
    & > div > div > button {
        height: 40px;
        width: 100px;
        font-weight: bold;
        font-size: 12px;
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid #ffffff;
        padding: 10px;
        margin: 10px;
        cursor: pointer;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
        transition: all 0.3s ease 0s;
    }
    /*Main button hover*/
    & > div > div > button:hover {
        background-color: #31BAFF;
        color: #ffffff;
    }

    & > hr {
        width: 80%;
    }

   
    @media (max-width: 1280px)
    {
        & > div {
            flex-direction: column;
            align-items: center;
        }
    }
`;



const BottomArea = styled.div`
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
    background-color: #F7F9FA;


    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    @media (max-width: 1280px)
    {
        & > div {
            flex-direction: column;
            align-items: center;
        }
    
    }
`;


const BottomButtons = styled.button`
    height: 100px;
    width: 100px;
    font-weight: bold;
    font-size: 1rem;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ffffff;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    transition: all 0.3s ease 0s;


    &:hover {
        background-color: #31BAFF;
        color: #ffffff;
    }

    @media (max-width: 1280px)
    {
        height: 80px;
        width: 60%;
        font-size: 0.8rem;
    }
`;

const AddButton = styled.button`
    height: 50px;
    width: 40px;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    background-color: #31BAFF;
    border: 1px solid #ffffff;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    transition: all 0.3s ease 0s;

    &:hover {
        background-color: #ffffff;
        color: #31BAFF;
    }
`;

const ListContainer = styled.ul`
    list-style: none;
    text-align: center;
    margin-top: auto;
    padding: 0;
    width: 100%;
    & > li {
        transition: all 0.3s ease 0s;
        width: 100%;
    }
`;





const Home = () => {
    const [url, setUrl] = React.useState([]);
    const onAddItem = () => {
        setUrl([...url, <URLItem />]);
    }

    const cafeurl ="https://cafe.naver.com/automationforyou"
    return (
        <div>
            <TitleArea>
                <div>
                <h1>완전 무료로 사용하는 부업 잘하는 비법</h1>
                <h1>WORK PAGE</h1>
                </div>
                <p>부업부자 김대리의 비밀 사이트</p>
            </TitleArea>
            <div>
                <img src={banner} alt='cafe banner' onClick={()=>window.open(cafeurl)}/>
            </div>
            <MainArea>
                <div>
                    <div>
                        <button>최근 방문지</button>
                        <button>블로그 최신글</button>
                    </div>
                    <div>
                        <button>일괄 생성</button>
                        <button>링크 출력</button>
                    </div>
                </div>
                <hr></hr>
                 <div>
                    <URLItem />
                    <ListContainer>
                        {url.map((item, index) => {
                            return <li key={index}>{item}</li>
                        }
                        )}
                    </ListContainer>
                </div>
                {
                    url.length < 9 ? <AddButton onClick={onAddItem} >+</AddButton> : null
                }
            </MainArea>
            <div>
                <img src={banner} alt='cafe banner' onClick={()=>window.open(cafeurl)}/>
            </div>
            <BottomArea>
                <h1>이 많은 기능을 WORK PAGE에선 무료로 사용합니다!</h1>
                <div>
                    <BottomButtons>1일 100개 생성</BottomButtons>
                    <BottomButtons>생성 URL 유지 기간 설정</BottomButtons>
                    <BottomButtons>최대 10개 동시 변경</BottomButtons>
                    <BottomButtons>최근 접속한 주소 자동 입력</BottomButtons>
                    <BottomButtons>특정 사이트 최신글 불러오기</BottomButtons>
                </div>
            </BottomArea>
        </div>
    );
};

export default Home;