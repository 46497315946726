import BackLink from "../pages/BackLink/BackLink";
import Home from "../pages/Home/Home";
import ImageChange from "../pages/ImageChange/ImageChange";
import Keyword from "../pages/Keyword/Keyword";
import MoreMenu from "../pages/MoreMenu/MoreMenu";
import SiteStatistics from "../pages/SiteStatistics/SiteStatistics";
import Thumbnail from "../pages/Thumbnail/Thumbnail";
import VideoChange from "../pages/VideoChange/VideoChange";


const ROUTES = [
    {
      path: "/",
      component: Home,
      name: "단축URL",
      subtitle: "WORKPAGE",
    },
    {
        path: "/BackLink",
        component: BackLink,
        name: "백링크 생성",
        subtitle: "백링크 생성",
     },
     {
        path: "/Thumbnail",
        component: Thumbnail,
        name: "썸네일 생성",
        subtitle: "썸네일 생성",
     },
     {
      path: "/Keyword",
      component: Keyword,
      name: "키워드 생성",
      subtitle: "키워드 생성",
      },
      {
         path: "/SiteStatistics",
         component: SiteStatistics,
         name: "사이트 통계",
         subtitle: "사이트 통계",
      },
      {
         path: "/ImageChange",
         component: ImageChange,
         name: "이미지 형식 변경",
         subtitle: "이미지 형식 변경",
      },
      {
         path: "/VideoChange",
         component: VideoChange,
         name: "영상 형식 변경",
         subtitle: "영상 형식 변경",
      },
      {
         path: "/MoreMenu",
         component: MoreMenu,
         name: "더보기",
         subtitle: "더보기",
      },
];

export default ROUTES;