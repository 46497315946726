import React from 'react';

const BackLink = () => {
    return (
        <div>
        <h1>Back Link</h1>
        </div>
    );
};

export default BackLink;