import React from "react";
import styled from "styled-components";

const InputFieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > input { 
        width: 100%;
        height: 20px;
        padding: 10px;
        border: 1px solid #000000;
        border-radius: 5px;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
        margin: 10px;
        font-size: 1rem;
    }

    & > button {  
        height: 40px;
        width: 100px;
        font-weight: bold;
        font-size: 12px;
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid #ffffff;
        margin: 10px;
        cursor: pointer;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
        transition: all 0.3s ease 0s;
    }

    & > button:hover {
        background-color: #31BAFF;
        color: #ffffff;
    }
    
    @media (max-width: 1280px)
    {
        & > input {
            width: 80%;
            height: 20px;
            font-size: 1rem;
        }

        & > button {
            height: 50px;
            width: 10%;
            font-size: 1rem;
        }
    }
`;


const URLItem = () => {
    return (
        <InputFieldContainer>
            <input type="text" placeholder="https://..." onClick={(event) => console.log(event.target)}></input>
            <button>링크 생성</button>
        </InputFieldContainer>
    );
}

export default URLItem;