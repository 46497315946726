import React from 'react';

const Thumbnail = () => {
    return (
        <div>
        <h1>Thumbnail Page</h1>
        </div>
    );
};

export default Thumbnail;