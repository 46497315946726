import React from 'react';

const MoreMenu = () => {
    return (
        <div>
        <h1>More Menu</h1>
        </div>
    );
};

export default MoreMenu;